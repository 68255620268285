import { defineStore } from 'pinia'
import 'firebase/auth'
import Bugsnag from '@bugsnag/js'

export const userStore = defineStore('user', {
  state: () => ({
    // USER SCHEMA:
    //  {
    //    loggedIn: false,
    //    displayName: 'Login',
    //    defaultCampus: String,
    //    email: '',
    //    picture: null,
    //    data: {
    //      checked: false,
    //      courses: []
    //    }
    //  }
    userInfo: null
  }),
  actions: {
    setUserState (user) {
      if (user) {
        Bugsnag.setUser(user.id, user.email, user.displayName)
        this.userInfo = { ...user, data: { checked: false, courses: [] } }
      } else {
        Bugsnag.setUser(null, null, null)
        this.userInfo = null
      }
    }
  }
})
