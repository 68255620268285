import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminRouter from './AdminRouter'
import ClientRouter from './ClientRouter'

Vue.use(VueRouter)

const isAdmin = process.env.VUE_APP_ENTRYPOINT === 'admin'
const targetRouter = isAdmin ? AdminRouter : ClientRouter

export default targetRouter
