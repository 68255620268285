import { defineStore } from 'pinia'

export const errorStore = defineStore('error', {
  state: () => ({
    graphQL: null,
    auth: {
      error: false,
      message: ''
    }
  }),
  actions: {
    resetAuthError () {
      this.auth.error = false
      this.auth.message = ''
    }
  }
})
