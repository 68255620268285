<template>
  <v-container class="contentContainer px-0 pb-0">
    <div v-if="store.app.onDesktop && store.app.liveAnimations" class="animatedShapes">
      <span v-for="colour in ['purple', 'medium-blue', 'light-blue', 'red', 'orange', 'cyan']" :key="colour" :class="colour"/>
    </div>
    <UpdateModal :show-updates="showUpdates"/>
    <!-- SNACKBAR - Snackbar for COOKIE notification -->
    <v-snackbar v-model="cookie" color="#009FFF" class="pa-4" width="300px" elevation="12"
                :bottom="this.store.app.onMobile" :left="!this.store.app.onMobile" timeout="-1">
      <v-col>
        <p class="text-h5 font-weight-bold white--text">We use 🍪 and Google Analytics to enhance the user experience</p>
        <p class="text-body-1 mb-0 white--text">
          Select <strong>'Accept All'</strong> to agree and continue. You consent to the use of cookies and analytics
          if you continue to use this website.</p>
        <v-row class="px-8 py-2" justify="center">
          <v-btn color="#000000" class="mt-8 w-full" @click="acceptCookie">Accept All</v-btn>
        </v-row>
      </v-col>
    </v-snackbar>

    <!-- MAIN HOME - Text heading and autocomplete/search elements -->
    <v-row class="searchRow" justify="center">
      <v-col class="contentMaxWidth">
        <v-row justify="center">
          <v-icon size="115" color="accent" class="floating">mdi-book-open-page-variant</v-icon>
        </v-row>
        <v-row class="pt-4 pa-2" justify="center">
          <h1 class="text-center font-weight-bold text-h3 text-sm-h2 pt-2 pb-1 mb-0" style="z-index: 2 !important;">
            Find and browse courses<br v-if="$vuetify.breakpoint.smAndUp"/> offered at UofT
          </h1>
        </v-row>
        <v-row class="pt-12 pb-4 ma-0" justify="center">
          <v-row class="searchBar">
            <v-combobox rounded outlined clearable hide-no-data :items="store.data.courseList"
                        prepend-inner-icon="mdi-magnify" label="Course or Keyword ( Press ' / ' )"
                        cache-items ref="searchBar" hide-details @input="courseSelected" background-color="background"/>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <!-- FEATURES - Cards showing main site features -->
    <v-row justify="center" class="px-5 pt-0">
      <v-col class="contentMaxWidth">
        <v-img class="homeImage" :lazy-src="require('@/assets/' + ((store.app.darkMode) ? 'dark-home.png' : 'home.png'))"
               :src="require('@/assets/' + ((store.app.darkMode) ? 'dark-home.png' : 'home.png'))"/>
        <v-row justify="center" class="my-8">
          <v-col style="max-width: 700px" class="text-center">
            <h1>One place with all the information</h1>
            <p class="text--secondary">
              Packed with features and a wealth of data ranging from course info, drop rates, bird counts, ratings,
              and reviews for over 11,500 courses, you can be sure you make the right decision, one course at a time.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center" class="mx-12"><v-divider style="max-width: 800px"/></v-row>
        <v-row justify="center" class="mt-12">
          <v-col class="mt-2 mt-sm-4 mb-6 px-2">
            <h1 class="text-center text-sm-left mb-4 mb-sm-0 px-3">Features to Explore</h1>
            <v-row class="pt-6 pb-4" justify="center">
              <v-col v-for="(feature, idx) in features" :key="idx" lg="4" md="4" xs="12" class="pa-0 pa-sm-4">
                <v-card class="featureCard py-sm-5 d-flex flex-column text-center text-md-left" color="transparent" flat>
                  <v-card-title class="justify-center justify-md-start">
                    <div class="rounded" :style="'background-color: var(--v-' + feature.colour + '-base'">
                      <v-icon x-large class="ma-3" color="#FFFFFF">{{ feature.icon }}</v-icon>
                    </div>
                  </v-card-title>
                  <v-card-text class="text-h5 pb-0 text--text font-weight-bold">{{ feature.title }}</v-card-text>
                  <v-card-text class="pt-2 text--secondary">{{ feature.text }}</v-card-text>
                  <v-spacer/>
                  <v-card-actions class="px-4 pb-8 pb-md-0 d-flex justify-center justify-md-start">
                    <p class="font-weight-medium accent--text hover" @click="$router.push(feature.page)">
                      Get started
                      <v-icon color="accent" small class="mb-0">mdi-chevron-right</v-icon>
                    </p>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb-12 py-12" justify="center" style="background: var(--v-border-base)">
      <v-col class="text-center">
        <h1 class="justify-center">Every Step of the Way</h1>
        <p class="text--secondary pb-10 px-8">Dedicated to being the one-stop-shop when it comes to course enrolment.</p>
        <v-row class="px-4">
          <v-col cols="12" class="col-sm-6 col-lg-3">
            <div class="blob b1 pa-8">
              <UndrawFileSearching primary-color='#003c85' height="165"/>
            </div>
            <div class="px-8">
              <h2>1. Discover</h2>
              <p class="text--secondary">Browse through +11,500 courses in the directory or explore the drop map.</p>
            </div>
          </v-col>
          <v-col cols="12" class="col-sm-6 col-lg-3">
            <div class="blob b2 pa-8">
              <UndrawChoice primary-color='#003c85' height='165'/>
            </div>
            <div class="px-8">
              <h2>2. Evaluate</h2>
              <p class="text--secondary">Compare courses across a variety of metrics to find the best fit for you.</p>
            </div>
          </v-col>
          <v-col cols="12" class="col-sm-6 col-lg-3">
            <div class="blob b3 pa-8">
              <UndrawCalendar primary-color='#003c85' height="165"/>
            </div>
            <div class="px-8">
              <h2>3. Plan</h2>
              <p class="text--secondary">Create and generate the ideal schedule with all the courses you need.</p>
            </div>
          </v-col>
          <v-col cols="12" class="col-sm-6 col-lg-3">
            <div class="blob b4 pa-8">
              <UndrawChatting primary-color='#003c85' height='165'/>
            </div>
            <div class="px-8">
              <h2>4. Share</h2>
              <p class="text--secondary">Share timetables, comparisons, course links, and more with everyone.</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="px-5 my-8">
      <v-col class="contentMaxWidth text-center" style="max-width: 750px">
        <v-icon size="150" color="accent" class="mb-12">mdi-book-open-page-variant</v-icon>
        <h1 class="mb-4">Our Commitment</h1>
        <p>Dedicated to being the one-stop shop for UofT students when course enrolment rolls
          around, we are continuously looking for ways to improve and enhance the services we provide. While not
          officially affiliated with the University of Toronto or related identities, we endeavour to provide an
          experience that is second to none. Among other things, that includes an ad-free environment, clean and
          user-friendly interface, and access to a wealth of helpful course, professor, and campus information.</p>
        <p>As we continue to evolve and improve UofT Index, one thing that has and will always
          remain constant is our commitment to the UofT community and providing the information and tools needed to
          help you succeed. </p>
        <p class="my-8 font-weight-medium">~ Your UofT Index Team</p>
      </v-col>
    </v-row>
    <!-- FEATURE REQUESTS - Banner for users to request new features -->
    <v-row class="pb-12 px-5" justify="center">
      <v-col class="text-center contentMaxWidth">
        <v-card class="py-12 px-6 px-sm-12" style="background: #003C85; border-radius: 20px">
          <h1 class="justify-center pb-5 white--text">Missing something?</h1>
          <p class="justify-center text-body-1 white--text">UofT Index was designed to provide
            students with all the necessary information needed to make the best possible choice when it comes to
            course selections. If you feel like something is missing or would like to see a new feature added,
            feel free to make a request!</p>
          <v-btn color="warning" depressed class="mt-7 mb-4 font-weight-bold white--text" href="https://uoftindex.canny.io/feature-requests" target="_blank">
            <v-icon color="#FFFFFF" class="mr-3">mdi-cake-variant</v-icon>Request Feature
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UpdateModal from '@/components/UpdateModal'

import UndrawFileSearching from 'vue-undraw/UndrawFileSearching'
import UndrawChoice from 'vue-undraw/UndrawChoice'
import UndrawCalendar from 'vue-undraw/UndrawCalendar'
import UndrawChatting from 'vue-undraw/UndrawChatting'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'Home',
  components: { UpdateModal, UndrawFileSearching, UndrawChoice, UndrawCalendar, UndrawChatting },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    searchQuery: null,
    cookie: false,
    showUpdates: false,
    features: [
      {
        title: 'Timetable Builder',
        text: `
              Quickly generate conflict-free timetables with all of your favourite courses. Explore different combinations
              of lecture and tutorial timings and find what works best for your schedule.
        `,
        icon: 'mdi-calendar',
        img: 'timetable.png',
        page: 'timetable',
        colour: 'info'
      },
      {
        title: 'Course Reviews',
        text: 'Often students reach out on Reddit, Facebook, etc., for course recommendations. This feature is' +
          ' intended to help this process by providing comments and various course-related metrics.',
        icon: 'mdi-star',
        img: 'rating.png',
        page: 'directory',
        colour: 'warning'
      },
      {
        title: 'Course Trees',
        text: 'Designed to reduce the time and tabs taken when looking into course prerequisites or post-requisites' +
          ' by providing students with the entire course tree for any given course.',
        icon: 'mdi-sitemap',
        img: 'prereqs.png',
        page: 'tree',
        colour: 'success'
      },
      {
        title: 'Drop Rates',
        text: 'From the course directory, students can view the drop rate for any course. Additionally,' +
          ' there is a dedicated drop rate map which students can interact with and view the drop rates across' +
          ' various criteria.',
        icon: 'mdi-finance',
        img: 'drop.png',
        page: 'droprate',
        colour: 'error'
      },
      {
        title: 'Course History',
        text: 'This feature is designed to provide a way of comparing profs and courses they have taught.' +
          ' When viewing a course, students can view past offerings, profs, and session drop rates as well.',
        icon: 'mdi-history',
        img: 'history.png',
        page: 'directory',
        colour: 'accent'
      },
      {
        title: 'Compare Courses',
        text: `
              Having trouble deciding between courses? This feature allows users to compare up to three courses
              across a variety of information that often factor into the decision process.
        `,
        icon: 'mdi-scale-unbalanced',
        img: 'compare.png',
        page: 'compare',
        colour: 'tooltip'
      }
    ]
  }),
  created () {
    // Check if they agreed to cookies
    if (!this.store.app.cookieOk) {
      const cookieOk = localStorage.getItem('cookie-agreed')
      if (!cookieOk) {
        this.cookie = true
        localStorage.setItem('latest-version', this.store.app.latestVersion)
      }
      this.store.app.setCookieOk()
    }

    // Check if they saw the latest updates
    if (!this.store.app.latestUpdate) {
      const latestUpdate = localStorage.getItem('latest-version')
      if (!latestUpdate || latestUpdate !== this.store.app.latestVersion) this.showUpdates = true
      this.store.app.setLatestUpdate()
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.store.errors.graphQL) {
        vm.$toast.error('An error occurred when contacting the server. Please try again later.')
        vm.store.errors.graphQL = null
      }
    })
  },
  mounted () {
    document.addEventListener('keydown', this.onDocumentKeydown)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onDocumentKeydown)
  },
  methods: {
    debug () {
      console.log('app store', this.store.data.courseList)
    },
    executeSearch (showError) {
      if (this.searchQuery) {
        const courseIDX = this.store.data.courseList.findIndex((element) => { return element === this.searchQuery })
        if (courseIDX > -1) {
          const courseCode = this.store.data.courseList[courseIDX].slice(0, 8)
          // GTAG
          this.$gtag.event('home_' + courseCode, { value: 1 })
          this.$router.push({ path: '/courses', query: { c: courseCode } })
        } else {
          // GTAG
          this.$gtag.event('home_search', { value: 1 })

          this.store.data.setDirectoryStateBykey('searchQuery', this.searchQuery.trim())
          this.store.data.setDirectoryStateBykey('retainQuery', true)
          this.$router.push('/directory')
        }
      } else {
        if (showError) this.$toast.warning('Enter a course or keyword before searching!')
      }
    },
    courseSelected (course) {
      this.searchQuery = course
      this.executeSearch(false)
    },
    onDocumentKeydown (e) {
      if (e.key === '/' && e.target !== this.$refs.searchBar.$refs.input) {
        e.preventDefault()
        this.$refs.searchBar.focus()
      }
    },
    acceptCookie () {
      localStorage.setItem('cookie-agreed', 'true')
      this.store.app.cookieOk = true
      this.cookie = false
    }
  }
}
</script>

<style scoped lang="scss">
  v-img {
    max-width: 100%;
    z-index: 2;
  }
  .v-input__append-inner {
    justify-items: center;
    justify-content: center;
  }
  >>>.theme--light.v-timeline::before {
    background: none;
  }
  >>>.theme--light.v-timeline .v-timeline-item__dot {
    display: none;
  }
  .animatedShapes {
    position: absolute;
    margin-top: 20vh;
  }
  .searchRow {
    height: 65vh;
    margin: 0;
    padding: 0 10px;
    min-height: 650px;
    justify-content: center;
    align-items: center;
  }
  .searchBar {
    max-width: 850px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
  }
  .featureCard {
    min-width: 300px;
  }
  >>>.v-snack__content {
    min-width: 100% !important;
  }
  .blob {
    background-size: contain;
    background-position: top;
  }
  .b1 {
    background-image: url("./../assets/ui/blob1.svg");
  }
  .b2 {
    background-image: url("./../assets/ui/blob2.svg");
  }
  .b3 {
    background-image: url("./../assets/ui/blob3.svg");
  }
  .b4 {
    background-image: url("./../assets/ui/blob4.svg");
  }

  $colors: (purple: #7E57C2, medium-blue: #1E88E5, light-blue: #1B74C2, red: #BF1537, orange: #D89648, cyan: #78ffba);

  span,
  span::before,
  span::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9;
    transform-origin: top;
    transition: all 25s linear 0s;
    z-index: 0 !important;
  }

  @function random-num($min, $max) {
    @return floor(random() * ($max - $min) + $min);
  }

  @function random-calc($number) {
    $max: 100 - $number;
    $min: $number * -1;
    @return random-num($min, $max);
  }

  @each $color-name, $color-hex in $colors {
    // Initial top and left positions
    $random1: random(100);
    $random2: random(100);

    // Animated top and left positions
    $random3: random(100);
    $random4: random(100);

    // Animated top and left end positions
    $random5: random(100);
    $random6: random(100);

    .#{"" + $color-name} {
      $size: random-num(5,50) + px;
      animation: #{$color-name} linear 150s alternate infinite;
      border: 2px solid #{$color-hex};
      border-radius: 100%;
      width: $size;
      height: $size;
      transform: translate3d($random1 + vw, $random2 + vh, 0);
    }

    .#{"" + $color-name}::before {
      animation: #{"" + $color-name}-pseudo linear 180s alternate infinite;
      background: #{$color-hex};
      border: 2px solid #{$color-hex};
      width: random-num(5,200) + px;
      height: random-num(5,200) + px;
      transform:
        translate3d(random-calc($random1) + vw, random-calc($random2) + vh, 0)
        rotate((random(360)) + deg);
    }

    .#{"" + $color-name}::after{
      animation: #{"" + $color-name}-pseudo linear 180s alternate infinite;
      border: 2px solid #{$color-hex};
      width: random-num(5,100) + px;
      height: random-num(5,100) + px;
      transform:
        translate3d(random-calc($random1) + vw, random-calc($random2) + vh, 0)
        rotate((random(360)) + deg);
    }

    @keyframes #{"" + $color-name} {
      50% {
        transform: translate3d($random3 + vw, $random4 + vh, 0);
      }
      100% {
        transform: translate3d($random5 + vw, $random6 + vh, 0);
      }
    }

    @keyframes #{"" + $color-name}-pseudo {
      33% {
        transform:
          translate3d(random-calc($random3) + vw, random-calc($random4) + vh, 0)
          rotate((random(360)) + deg);
      }
      100% {
        transform:
          translate3d(random-calc($random5) + vw, random-calc($random6) + vh, 0)
          rotate((random(360)) + deg);
      }
    }
  }
  .floating {
    text-shadow: 0 15px 5px rgba(0,0,0,0.1),
    10px 20px 5px rgba(0,0,0,0.05),
    -10px 20px 5px rgba(0,0,0,0.05);
  }
</style>
