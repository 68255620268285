import VueRouter from 'vue-router'

export default new VueRouter({
  mode: process.env.VUE_APP_ENTRYPOINT === 'admin' ? 'hash' : 'history', // BOTH routers need to use same history mode
  base: '/admin',
  routes: [
    {
      path: '/home',
      name: 'UofT Index Admin',
      component: () => import('../views/admin/AdminHome.vue')
    },
    {
      path: '*',
      redirect: '/home'
    }
  ]
})
