/**
 * Returns a deep clone of given JavaScript object.
 * THIS IS A VERY EXPENSIVE OPERATION!!
 * @param {Object} obj
 * @returns {Object}
 */
export function deepClone (obj) {
  // TODO: Replace with better implementation, probably 3rd-party library
  return JSON.parse(JSON.stringify(obj))
}

export async function sleep (ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms)
  })
}

/**
 * De-dupe entries in an array. Entries must be comparable.
 *
 * @param arr {any[]}
 * */
export function dedupe (arr) { return [...(new Set(arr))] }

export function isEmptyObject (obj) { return Object.keys(obj).length === 0 }

/** Given an object, return a copy with its keys and valus swapped.
 *
 * @param {any} obj
 *
 * @example
 *    swapObjectKeyValues({ hi: 'bye', one: 'two' })
 *    => { bye: 'hi', two: 'one' }
*/
export function swapObjectKeyValues (obj) {
  const newObj = { }
  for (const key in obj) {
    newObj[obj[key]] = key
  }

  return newObj
}

/**
 * Returns a shuffled copy of the given array.
 * @param {any[]} arr
 * @returns {any[]}
 */
export function shuffleArray (arr) { return arr.sort((a, b) => 0.5 - Math.random()) }

/** Given a string and a length, trim the
 * string to that length followed by an ellipses.
 *
 * @param {string} str The string to cut
 * @param {number} amount The maximum number of characters to keep
 *
 * @example trimLength('Hello World!', 3) => 'Hel...'
 *
 */
export function trimLength (str, amount) { return str.length > amount ? str.slice(0, amount) + '...' : str }

export function clamp (number, min, max) { return Math.max(min, Math.min(number, max)) }

export const parseCookie = str => {
  if (str.trim() === '') return {}
  return str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})
}

export function sortSemesters (a, b) {
  const aYear = a.indexOf('-') + 1
  const bYear = b.indexOf('-') + 1
  if (a.slice(aYear, aYear + 2) !== b.slice(bYear, bYear + 2)) {
    return a.slice(aYear, aYear + 2) - b.slice(bYear, bYear + 2)
  } else {
    if ((a[0] === 'W') || (a[0] === 'S' && b[0] === 'F')) return -1
    if ((a[0] === 'F') || (a[0] === 'S' && b[0] === 'W')) return 1
    return 0
  }
}

export function formatTime (timeString) {
  const [hourString, minute] = timeString.split(':')
  const hour = +hourString % 24
  const minutes = parseInt(minute) ? ':' + minute : ''
  return (hour % 12 || 12) + minutes + (hour < 12 ? ' a.m.' : ' p.m.')
}

/**
 * Gives a local time string of the given date.
 * E.g. "8:00 a.m.", "11:00 p.m.", etc.
 *
 * @param {string |  Date} date
 * @return {string}
 * */
export function localTimeString (date) {
  return new Date(date).toLocaleTimeString('en-CA', { hour: 'numeric', minute: '2-digit' })
}

export function hexOpacity (hex, opacity) {
  let color = hex.slice(1)
  color += Math.round(opacity * 255).toString(16).padStart(2, '0')
  return `#${color}`.toUpperCase()
}

/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 */
export function getRelativeTimeString (date, lang = navigator.language) {
  // Allow dates or times to be passed
  const timeMs = typeof date === 'number' ? date : date.getTime()

  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000)

  // Array representing one minute, hour, day, week, month, etc in seconds
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity]

  // Array equivalent to the above but in the string representation of the units
  const units = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year']

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex(cutoff => cutoff > Math.abs(deltaSeconds))

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1

  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' })
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex])
}
