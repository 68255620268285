import { appStore } from './app'
import { dataStore } from './data'
import { errorStore } from './error'
import { userStore } from './user'

/** Combine all stores into one, so we can use a single import. */
export const useAllStores = () => ({
  app: appStore(),
  user: userStore(),
  data: dataStore(),
  errors: errorStore()
})
